import React from "react";
import { Button } from "react-bootstrap";
import { HiX, HiOutlineCheck } from "react-icons/hi";
import ModalApprove from "./modals/ModalApprove";
import ModalReject from "./modals/ModalReject";
const Buttons = ({ bin, id }) => {
  const [openApprove, setOpenApprove] = React.useState(false);
  const [openReject, setOpenReject] = React.useState(false);
  return (
    <div style={{ position: "fixed", bottom: 20, right: 20 }}>
      <Button variant="danger" style={{ borderRadius: 5, marginRight: 20 }} onClick={() => setOpenReject(true)}>
        <HiX size={50} />
      </Button>
      <Button variant="success" style={{ borderRadius: 5 }} onClick={() => setOpenApprove(true)}>
        <HiOutlineCheck size={50} />
      </Button>
      <ModalApprove show={openApprove} setShow={setOpenApprove} id={id} bin={bin} />
      <ModalReject show={openReject} setShow={setOpenReject} id={id} />
    </div>
  );
};

export default Buttons;
