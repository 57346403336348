import React from "react";
import { connect } from "react-redux";
import Routing from "../Routes/Routing";
import Auth from "./Auth";
import Loader from "./ui/Loader";
const MainPage = ({ loading, isAuth }) => {
  return (
    <div>
      {loading && <Loader />}
      <Auth />
      {isAuth && <Routing />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.ui.loading,
  isAuth: state.auth,
});

export default connect(mapStateToProps)(MainPage);
