// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA3x6clN9vK6--oLLP_rkPIxM8arhB-h_Y",
  authDomain: "amazing-youth-recycle--dev.firebaseapp.com",
  databaseURL: "https://amazing-youth-recycle--dev-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "amazing-youth-recycle--dev",
  storageBucket: "gs://amazing-youth-recycle--dev.appspot.com",
  messagingSenderId: "539846028567",
  appId: "1:539846028567:web:62397f0b535ee7dfbf9b52",
  measurementId: "G-LBG6LHS38M",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
