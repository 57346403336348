import { ref, remove } from "firebase/database";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { database } from "../../../firebase";
import { RemoveBin, SetDeleteBin, SetOpenDeleteBin } from "../../../redux/actions";
import Loader from "../Loader";
const ModalConfirmDeleteBin = ({ show, setShow, bin, handleDelete }) => {
  const [loading, setLoading] = React.useState(false);
  const handleAccept = async () => {
    setLoading(true);
    try {
      // const imgRef = sRef(storage, `images/${bin.id}`);
      // deleteObject(imgRef);
      await remove(ref(database, `bins/${bin.type}/${bin.id}`));
      handleDelete(bin.type, bin.id);
      //   await remove(ref(database, `images/${id}`));
      //   await remove(ref(database, `pending-demands/${id}`));
      //   removeFromList(id);
      //   navigate("/check-bins");
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setShow(false);
  };

  const handleCancel = async () => {
    // moveBin(bin.type, bin.id, bin.oldCoords);
    setShow(false);
  };

  return (
    <Modal centered show={show} onHide={() => setShow(false)}>
      {loading && <Loader />}
      <Modal.Header className="d-flex justify-content-center align-items-center">Αφαίρεση Κάδου</Modal.Header>
      <Modal.Body className="d-flex justify-content-center align-items-center">
        Ο κάδος θα αφαιρεθεί απο την εφαρμογή
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-evenly align-items-center">
        <Button onClick={handleCancel} variant="danger">
          Ακύρωση
        </Button>
        <Button onClick={handleAccept} variant="success">
          Επιβεβαίωση
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  show: state.ui.deleteBin.show,
  bin: state.ui.deleteBin.bin,
});

const mapDispatchToPros = (dispatch) => ({
  setShow: (value) => dispatch(SetOpenDeleteBin(value)),
  handleDelete: (type, id) => dispatch(RemoveBin(type, id)),
});

export default connect(mapStateToProps, mapDispatchToPros)(ModalConfirmDeleteBin);
