import { get, ref, update } from "firebase/database";
import React from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { database } from "../../../firebase";
import { SetPinShow } from "../../../redux/actions";

const ModalChangePin = ({ show, setShow }) => {
  const [error, setError] = React.useState("");
  const [errorOld, setErrorOld] = React.useState(false);
  const [errorNew, setErrorNew] = React.useState(false);

  const [oldPin, setoldPin] = React.useState("");

  const oldPinref = React.useRef();
  const NewPinref = React.useRef();
  const ConfirmNewPinref = React.useRef();

  const fetchOldPin = async () => {
    await get(ref(database, "settings/pin")).then((snap) => setoldPin(snap.val()));
  };

  const changePin = async (e) => {
    e.preventDefault();
    setError(undefined);
    setErrorOld(false);
    setErrorNew(false);
    if (oldPin != oldPinref.current.value) {
      setError("Λανθασμένο παλιό PIN");
      setErrorOld(true);
      return;
    }

    if (NewPinref.current.value != ConfirmNewPinref.current.value) {
      setError("Τα νέα PIN δεν ταιριάζουν");
      setErrorNew(true);
      return;
    }
    await update(ref(database, "settings"), { pin: NewPinref.current.value });
    setShow(false);
  };

  React.useEffect(() => {
    if (show) {
      setError(undefined);
      setErrorOld(false);
      setErrorNew(false);
      fetchOldPin();
    }
  }, [show]);

  return (
    <Modal centered backdrop="static" keyboard={false} show={show} onHide={() => setShow(false)}>
      <Modal.Body>
        <Form onSubmit={changePin}>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form.Control
            type="password"
            maxLength="4"
            pattern="\d{4}"
            required
            inputMode="numeric"
            placeholder="Old PIN"
            className={`${errorOld ? "border-danger" : ""}`}
            ref={oldPinref}
          />
          <Form.Control
            type="password"
            maxLength="4"
            pattern="\d{4}"
            required
            inputMode="numeric"
            placeholder="New PIN"
            ref={NewPinref}
            className={`my-2 ${errorNew ? "border-danger" : ""}`}
          />
          <Form.Control
            type="password"
            maxLength="4"
            pattern="\d{4}"
            required
            inputMode="numeric"
            className={`${errorNew ? "border-danger" : ""}`}
            placeholder="Confirm new PIN"
            ref={ConfirmNewPinref}
          />
          <div className="mt-3 d-flex justify-content-evenly align-items-center">
            <Button variant="danger" onClick={() => setShow(false)}>
              Cancel
            </Button>
            <Button variant="success" type="submit">
              Confirm
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  show: state.ui.pin,
});

const mapDispatchToProps = (dispatch) => ({
  setShow: (value) => dispatch(SetPinShow(value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ModalChangePin);
