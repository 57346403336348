import React from "react";
import { Button, Modal } from "react-bootstrap";
import { FaMapMarkerAlt } from "react-icons/fa";
import { HiX } from "react-icons/hi";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

// const Marker = () => (
//   <div style={{ position: "relative" }}>
//     <FaMapMarkerAlt style={{ position: "absolute", top: -20, left: -20 }} color="black" size={30} />
//   </div>
// );

const ModalMap = ({ show, setShow, coords }) => {
  const defaultProps = {
    center: {
      lat: coords.latitude,
      lng: coords.longitude,
    },
    zoom: 16,
  };
  return (
    <Modal show={show} fullscreen onHide={() => setShow(false)}>
      <Modal.Body className="p-0">
        <LoadScript googleMapsApiKey="AIzaSyDdnBpe6v0pHKKqnlFHVBXhpFU87R0Peyc">
          <GoogleMap mapContainerStyle={{ height: "100%", width: "100%" }} center={defaultProps.center} zoom={10}>
            <Marker
              position={{
                lat: coords.latitude,
                lng: coords.longitude,
              }}
            />
          </GoogleMap>
        </LoadScript>
      </Modal.Body>
      <Button
        style={{ position: "fixed", top: 20, right: 20, borderRadius: 5 }}
        variant="danger"
        onClick={() => setShow(false)}
      >
        <HiX size={20} />
      </Button>
    </Modal>
  );
};

export default ModalMap;
