import React from "react";
import { Button, Modal } from "react-bootstrap";
import { HiX } from "react-icons/hi";

const ModalImage = ({ show, setShow, photo }) => {
  return (
    <Modal
      // contentClassName="transparent"
      show={show}
      fullscreen
      onHide={() => setShow(false)}
    >
      <Modal.Body className="p-0 d-flex flex-column align-items-end">
        {photo?.success && (
          <div className="image-container position-relative">
            <img alt="bin" src={photo.url} className="img-fluid" />
            <Button
              style={{ position: "absolute", top: 10, right: 10, borderRadius: 5 }}
              variant="danger"
              onClick={() => setShow(false)}
            >
              <HiX size={20} />
            </Button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalImage;
