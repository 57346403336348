const Loader = () => {
  return (
    <div className="loader">
      <div className="bouncing-loader ">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
export default Loader;
