import { get, ref } from "firebase/database";
import React from "react";
import { connect } from "react-redux";
import { database } from "../../firebase";
import {
  AddBinCoords,
  AddBinShow,
  SetBins,
  SetDeleteBin,
  SetLoading,
  SetOpenDeleteBin,
  SetOpenMoveBin,
  SetUpdBin,
} from "../../redux/actions";
import { GoogleMap, LoadScript, MarkerClusterer, Marker } from "@react-google-maps/api";
import ModalConfirmMoveBin from "../ui/modals/ModalConfirmMoveBin";
import { Button } from "react-bootstrap";
import { HiOutlineRefresh } from "react-icons/hi";
import ModalAddBin from "../ui/modals/ModalAddBin";
import ModalConfirmDeleteBin from "../ui/modals/ModalConfirmDeleteBin";
import styles from "../../assets/styles.json";
const ListBins = ({
  bins,
  handleSetBins,
  setOpenMoveBin,
  setBinToUpd,
  setAddBinShow,
  setAddBinCoords,
  handleLoading,
  hadleSetShowDeleteBin,
  hadleSetDeleteBin,
}) => {
  const [navBarHeight, setNavBarHeight] = React.useState(window.innerHeight);
  const [map, setMap] = React.useState();
  const [center, setCenter] = React.useState({ lat: 37.943256, lng: 23.648001 });
  const [isTouchingCluster, setIsTouchingCluster] = React.useState(false);
  const defaultProps = {
    center: {
      lat: 37.943256,
      lng: 23.648001,
    },
    zoom: 10,
  };

  const defaultMapOptions = {
    styles: styles,
  };

  const fetchBins = async () => {
    map?.center && setCenter({ lat: map.center.lat(), lng: map.center.lng() });
    handleLoading(true);
    await get(ref(database, "bins")).then((snapshot) => {
      handleSetBins(snapshot.val());
    });

    handleLoading(false);
  };

  React.useEffect(() => {
    fetchBins();
    const navbar = document.querySelector(".navbar");
    setNavBarHeight(window.innerHeight - navbar.offsetHeight);
  }, []);

  const handleDragEnd = (oldCoords, e, type, id) => {
    const newCoords = { latitude: e.latLng.lat(), longitude: e.latLng.lng() };
    setBinToUpd({ newCoords, oldCoords, id, type });
    setOpenMoveBin(true);
  };

  const handleMarkerClick = (markerProps, event, bin) => {
    console.log("Marker clicked", markerProps, event);
    hadleSetDeleteBin(bin);
    hadleSetShowDeleteBin(true);
  };

  const handleMapClick = (e) => {
    if (!isTouchingCluster) {
      console.log("Map clicked", e);
      const position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      const coords = { latitude: e.latLng.lat(), longitude: e.latLng.lng() };
      map.panTo(position);
      setAddBinCoords(coords);
      setAddBinShow(true);
    } else {
      console.log("Cluster clicked", e);
    }
    setIsTouchingCluster(false);
  };

  return (
    <div style={{ height: navBarHeight, width: "100%" }}>
      <LoadScript googleMapsApiKey="AIzaSyDdnBpe6v0pHKKqnlFHVBXhpFU87R0Peyc">
        <GoogleMap
          yesIWantToUseGoogleMapApiInternals
          mapContainerStyle={{ height: navBarHeight, width: "100%" }}
          center={center}
          zoom={defaultProps.zoom}
          onClick={handleMapClick}
          onLoad={(map) => setMap(map)}
          options={defaultMapOptions}
        >
          <MarkerClusterer onClick={() => setIsTouchingCluster(true)}>
            {(clusterer) => {
              return (
                bins &&
                Object.keys(bins).map((key) => {
                  switch (key) {
                    case "glass":
                      return Object.keys(bins.glass).map((key, index) => (
                        <Marker
                          key={index}
                          icon={require("../../assets/bottle.png")}
                          draggable={true}
                          onClick={(markerProps, event) =>
                            handleMarkerClick(markerProps, event, { type: "glass", id: key })
                          }
                          onDragEnd={(e) => handleDragEnd(bins.glass[key].coords, e, "glass", key)}
                          position={{
                            lat: parseFloat(bins.glass[key].coords.latitude),
                            lng: parseFloat(bins.glass[key].coords.longitude),
                          }}
                          clusterer={clusterer}
                        />
                      ));
                    case "clothes":
                      return Object.keys(bins.clothes).map((key, index) => (
                        <Marker
                          key={index}
                          icon={require("../../assets/shirt.png")}
                          draggable={true}
                          onClick={(markerProps, event) =>
                            handleMarkerClick(markerProps, event, { type: "clothes", id: key })
                          }
                          onDragEnd={(e) => handleDragEnd(bins.clothes[key].coords, e, "clothes", key)}
                          position={{
                            lat: parseFloat(bins.clothes[key].coords.latitude),
                            lng: parseFloat(bins.clothes[key].coords.longitude),
                          }}
                          clusterer={clusterer}
                        />
                      ));
                    case "battery":
                      return Object.keys(bins.battery).map((key, index) => (
                        <Marker
                          key={index}
                          icon={require("../../assets/battery.png")}
                          draggable={true}
                          onClick={(markerProps, event) =>
                            handleMarkerClick(markerProps, event, { type: "battery", id: key })
                          }
                          onDragEnd={(e) => handleDragEnd(bins.battery[key].coords, e, "battery", key)}
                          position={{
                            lat: parseFloat(bins.battery[key].coords.latitude),
                            lng: parseFloat(bins.battery[key].coords.longitude),
                          }}
                          clusterer={clusterer}
                        />
                      ));
                    default:
                      break;
                  }
                })
              );

              // bins?.clothes &&
            }}
          </MarkerClusterer>
        </GoogleMap>
      </LoadScript>
      <Button
        style={{ position: "fixed", bottom: 30, left: 0, marginLeft: 12, borderRadius: 5 }}
        variant="primary"
        onClick={() => fetchBins(true)}
      >
        <HiOutlineRefresh size={30} />
      </Button>
      <ModalConfirmMoveBin fetchBins={fetchBins} />
      <ModalConfirmDeleteBin />
      <ModalAddBin map={map} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  bins: state.bins,
});
const mapDispatchToPros = (dispatch) => ({
  handleSetBins: (value) => dispatch(SetBins(value)),
  setOpenMoveBin: (value) => dispatch(SetOpenMoveBin(value)),
  setBinToUpd: (value) => dispatch(SetUpdBin(value)),
  setAddBinShow: (value) => dispatch(AddBinShow(value)),
  setAddBinCoords: (value) => dispatch(AddBinCoords(value)),
  handleLoading: (value) => dispatch(SetLoading(value)),
  hadleSetShowDeleteBin: (value) => dispatch(SetOpenDeleteBin(value)),
  hadleSetDeleteBin: (value) => dispatch(SetDeleteBin(value)),
});

export default connect(mapStateToProps, mapDispatchToPros)(ListBins);
