import { push, ref, remove } from "firebase/database";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { database, storage } from "../../../firebase";
import { DeleteDemandBin } from "../../../redux/actions";
import Loader from "../Loader";
import { deleteObject, ref as sRef } from "firebase/storage";

const ModalReject = ({ show, setShow, id, removeFromList }) => {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const handleAccept = async () => {
    setLoading(true);
    try {
      const imgRef = sRef(storage, `images/${id}`);
      deleteObject(imgRef);

      await remove(ref(database, `pending-demands/${id}`));
      removeFromList(id);
      navigate("/check-bins");
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setShow(false);
  };

  return (
    <Modal centered show={show} onHide={() => setShow(false)}>
      {loading && <Loader />}
      <Modal.Header className="d-flex justify-content-center align-items-center">Απόρριψη Κάδου</Modal.Header>
      <Modal.Body className="d-flex justify-content-center align-items-center">Ο κάδος θα απορριφθεί</Modal.Body>
      <Modal.Footer className="d-flex justify-content-evenly align-items-center">
        <Button onClick={() => setShow(false)} variant="danger">
          Ακύρωση
        </Button>
        <Button onClick={handleAccept} variant="success">
          Επιβεβαίωση
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapDispatchToPros = (dispatch) => ({
  removeFromList: (value) => dispatch(DeleteDemandBin(value)),
});

export default connect(null, mapDispatchToPros)(ModalReject);
