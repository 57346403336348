export const Appreducer = (
  state = {
    demands: null,
    bins: null,
    ui: {
      loading: false,
      pin: false,
      moveBin: false,
      deleteBin: { show: false, bin: undefined },
      addBin: false,
    },
    current: {
      bin: null,
      demand: null,
    },
    auth: false,
  },
  action
) => {
  switch (action.type) {
    case "SET_DEMANDS":
      return { ...state, demands: action.value };
    case "SET_LOADING":
      return { ...state, ui: { ...state.ui, loading: action.value } };
    case "SET_PIN_SHOW":
      return { ...state, ui: { ...state.ui, pin: action.value } };
    case "SET_OPEN_MOVE_BIN":
      return {
        ...state,
        ui: {
          ...state.ui,
          moveBin: {
            ...state.ui.moveBin,
            show: action.value,
          },
        },
      };
    case "SET_OPEN_DELETE_BIN":
      return {
        ...state,
        ui: {
          ...state.ui,
          deleteBin: {
            ...state.ui.deleteBin,
            show: action.value,
          },
        },
      };
    case "SET_DELETE_BIN":
      return {
        ...state,
        ui: {
          ...state.ui,
          deleteBin: {
            ...state.ui.deleteBin,
            bin: action.value,
          },
        },
      };
    case "REMOVE_BIN":
      const binCopy = JSON.parse(JSON.stringify(state.bins));
      delete binCopy[action.bin_type][action.id];
      return { ...state, bins: binCopy };
    case "UPD_MOVE_BIN":
      return {
        ...state,
        ui: {
          ...state.ui,
          moveBin: {
            ...state.ui.moveBin,
            bin: action.value,
          },
        },
      };
    case "SET_ADD_BIN_SHOW":
      return {
        ...state,
        ui: {
          ...state.ui,
          addBin: {
            ...state.ui.addBin,
            show: action.value,
          },
        },
      };
    case "SET_ADD_BIN_COORDS":
      return {
        ...state,
        ui: {
          ...state.ui,
          addBin: {
            ...state.ui.addBin,
            bin: {
              ...state.ui.addBin.bin,
              coords: action.value,
            },
          },
        },
      };
    case "ADD_BIN_TO_REDUX":
      return {
        ...state,
        bins: {
          ...state.bins,
          [action.bin_type]: {
            ...state.bins[action.bin_type],
            [action.id]: {
              coords: action.coords,
            },
          },
        },
      };
    case "SET_AUTH":
      return { ...state, auth: action.value };
    case "SET_DEMAND_BIN":
      return { ...state, current: { ...state.current, demand: action.value } };
    case "SET_BINS":
      return { ...state, bins: action.value };
    case "MOVE_BIN":
      return {
        ...state,
        bins: {
          ...state.bins,
          [action.bin_type]: {
            ...state.bins[action.bin_type],
            [action.bin_id]: {
              ...state.bins[action.bin_type][action.bin_id],
              coords: action.coords,
            },
          },
        },
      };
    case "DELETE_DEMAND_BIN":
      const copy = JSON.parse(JSON.stringify(state.demands));
      if (copy != null) {
        delete copy[action.value];
        console.log(copy);
      }
      return { ...state, demands: copy };
    default:
      return state;
  }
};
