import React from "react";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { SetPinShow } from "../../redux/actions";
import { connect } from "react-redux";
import ModalChangePin from "./modals/ModalChangePin";

const NavBar = ({ handleSetShowPin }) => {
  const navigate = useNavigate();
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Navbar bg="dark" expand="lg">
      <Container fluid>
        <Button variant="primary" onClick={handleShow}>
          <GiHamburgerMenu />
        </Button>
        <Offcanvas show={show} onHide={handleClose} placement="start">
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link
                onClick={() => {
                  navigate("/");
                  handleClose();
                }}
              >
                Home
              </Nav.Link>
              <Nav.Link
                onClick={() => {
                  navigate("/bins");
                  handleClose();
                }}
              >
                Bins
              </Nav.Link>
              <Nav.Link
                onClick={() => {
                  navigate("/check-bins");
                  handleClose();
                }}
              >
                Check Bins
              </Nav.Link>
              <Nav.Link
                onClick={() => {
                  handleClose();
                  handleSetShowPin(true);
                }}
              >
                Change Pin
              </Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Offcanvas>
        <Navbar.Brand className="text-light">
          <div className="d-flex justify-content-center align-items-center">
            <img src="./logo192.png" style={{ width: 30 }} className="me-1" />
            Eco Navigator
          </div>
        </Navbar.Brand>
      </Container>
      <ModalChangePin />
    </Navbar>
  );
};

const mapDispatchToProps = (dispatch) => ({
  handleSetShowPin: (value) => dispatch(SetPinShow(value)),
});

export default connect(null, mapDispatchToProps)(NavBar);
