import { push, ref } from "firebase/database";
import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { database } from "../../../firebase";
import { AddBinShow, AddBinToRedux } from "../../../redux/actions";
import Loader from "../Loader";
const ModalAddBin = ({ show, setShow, bin, handleAddBin }) => {
  const [loading, setLoading] = React.useState(false);
  const [type, setType] = React.useState("glass");
  const handleAccept = async () => {
    setLoading(true);
    try {
      const key = await push(ref(database, `bins/${type}`), {
        coords: bin.coords,
      });
      handleAddBin(type, key.key, bin.coords);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setShow(false);
  };

  const handleCancel = async () => {
    setShow(false);
  };

  return (
    <Modal centered show={show} onHide={() => setShow(false)}>
      {loading && <Loader />}
      <Modal.Header className="d-flex justify-content-center align-items-center">Εισαγωγή Κάδου</Modal.Header>
      <Modal.Body className="d-flex justify-content-center align-items-center flex-column">
        <Form.Select onChange={(e) => setType(e.target.value)} value={type}>
          <option value="glass" defaultValue>
            Υαλικών
          </option>
          <option value="clothes">Ρουχισμού</option>
          <option value="battery">Μπαταρίας</option>
        </Form.Select>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-evenly align-items-center">
        <Button onClick={handleCancel} variant="danger">
          Ακύρωση
        </Button>
        <Button onClick={handleAccept} variant="success">
          Επιβεβαίωση
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  show: state.ui.addBin.show,
  bin: state.ui.addBin.bin,
});

const mapDispatchToPros = (dispatch) => ({
  setShow: (value) => dispatch(AddBinShow(value)),
  handleAddBin: (type, id, coords) => dispatch(AddBinToRedux(type, id, coords)),
});

export default connect(mapStateToProps, mapDispatchToPros)(ModalAddBin);
