import React from "react";
import { Card } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SetCurrentDemandBin } from "../../redux/actions";

const DemandCard = ({ demand, db_id, handleClick }) => {
  const navigate = useNavigate();
  const handleClickOnRow = () => {
    handleClick(db_id);
    navigate(`/check-bins/${db_id}`);
  };
  return (
    <Card onClick={handleClickOnRow} className="my-3 mx-2">
      <Card.Body>
        <div>Κάδος {{ glass: "Υαλικών", clothes: "Ρουχισμού", battery: "Μπαταριών" }[demand.bin_type]}</div>
      </Card.Body>
    </Card>
  );
};

const mapDispatchToProps = (dispatch) => ({
  handleClick: (value) => dispatch(SetCurrentDemandBin(value)),
});

export default connect(null, mapDispatchToProps)(DemandCard);
