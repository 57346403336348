export const SetDemands = (value) => ({
  type: "SET_DEMANDS",
  value,
});

export const SetAuth = (value) => ({
  type: "SET_AUTH",
  value,
});

export const SetLoading = (value) => ({
  type: "SET_LOADING",
  value,
});

export const SetCurrentDemandBin = (value) => ({
  type: "SET_DEMAND_BIN",
  value,
});

export const DeleteDemandBin = (value) => ({
  type: "DELETE_DEMAND_BIN",
  value,
});

export const SetBins = (value) => ({
  type: "SET_BINS",
  value,
});

export const MoveBin = (bin_type, bin_id, coords) => ({
  type: "MOVE_BIN",
  bin_type,
  bin_id,
  coords,
});

export const SetOpenMoveBin = (value) => ({
  type: "SET_OPEN_MOVE_BIN",
  value,
});

export const SetUpdBin = (value) => ({
  type: "UPD_MOVE_BIN",
  value,
});

export const AddBinShow = (value) => ({
  type: "SET_ADD_BIN_SHOW",
  value,
});

export const AddBinCoords = (value) => ({
  type: "SET_ADD_BIN_COORDS",
  value,
});

export const AddBinToRedux = (bin_type, id, coords) => ({
  type: "ADD_BIN_TO_REDUX",
  bin_type,
  id,
  coords,
});

export const SetPinShow = (value) => ({
  type: "SET_PIN_SHOW",
  value,
});

export const SetOpenDeleteBin = (value) => ({
  type: "SET_OPEN_DELETE_BIN",
  value,
});

export const SetDeleteBin = (value) => ({
  type: "SET_DELETE_BIN",
  value,
});

export const RemoveBin = (bin_type, id) => ({
  type: "REMOVE_BIN",
  bin_type,
  id,
});
