import React from "react";
import { ref, get } from "firebase/database";
import { database } from "../firebase";
import { SetDemands, SetLoading } from "../redux/actions";
import { connect } from "react-redux";
import DemandCard from "./ui/DemandCard";
import { Button } from "react-bootstrap";
import { BiRefresh } from "react-icons/bi";
import { MdOutlineReportProblem } from "react-icons/md";
const List = ({ demands, handleSetDemands, handleLoading }) => {
  const fetchDemads = async () => {
    handleLoading(true);
    const requests = ref(database, "pending-demands");
    await get(requests).then((snapshot) => {
      console.log(snapshot.val());
      handleSetDemands(snapshot.val());
    });
    handleLoading(false);
  };
  React.useEffect(() => {
    demands == null && fetchDemads();
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-center align-items-center my-3 mx-2">
        <div className="d-flex justify-content-between align-items-center w-100">
          <b>Κάδοι προς Έλεγχο</b>
          <Button onClick={fetchDemads}>
            <BiRefresh size={20} />
          </Button>
        </div>
      </div>
      <div>
        {demands != null ? (
          Object.keys(demands).map((key, index) => {
            return <DemandCard key={index} demand={demands[key]} db_id={key} />;
          })
        ) : (
          <div className="mt-4">
            <MdOutlineReportProblem size={100} color="black" />
            <div>No Data</div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToPros = (state) => ({
  demands: state.demands,
});

const mapDispathToProps = (dispatch) => ({
  handleSetDemands: (value) => dispatch(SetDemands(value)),
  handleLoading: (value) => dispatch(SetLoading(value)),
});

export default connect(mapStateToPros, mapDispathToProps)(List);
