import { get, ref } from "firebase/database";
import React from "react";
import { Button, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { database, storage } from "../firebase";
import { SetLoading } from "../redux/actions";
import { AiOutlineRollback } from "react-icons/ai";
import Buttons from "./ui/Buttons";
import { BsPinMapFill, BsCameraFill } from "react-icons/bs";
import ModalMap from "./ui/ModalMap";
import ModalImage from "./ui/ModalImage";
import NotFound from "../Routes/NotFound";
import { getDownloadURL, ref as sRef } from "firebase/storage";

const BinCheck = ({ handleLoading, binFromBrowser }) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [error, setError] = React.useState(false);
  const [photo, setPhoto] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [bin, setBin] = React.useState(binFromBrowser);

  const [openMap, setOpenMap] = React.useState(false);
  const [openImage, setOpenImage] = React.useState(false);

  const fetchFromURL = async () => {
    handleLoading(true);
    setLoading(true);
    try {
      const binref = ref(database, `pending-demands/${id}`);
      await get(binref).then(async (snapshot) => {
        if (snapshot.exists()) {
          setBin(snapshot.val());
        } else {
          setError(true);
          console.log("No Data");
          return;
        }
      });
    } catch (error) {
      setError(true);
      console.log(error);
    }
    handleLoading(false);
    setLoading(false);
  };

  const fetchimage = async () => {
    if (photo?.success) {
      setOpenImage(true);
      return;
    }
    handleLoading(true);
    try {
      const imageRef = sRef(storage, `images/${id}`);
      const url = await getDownloadURL(imageRef);
      // await get(imageRef).then((base) => {
      // });
      console.log(url);
      setPhoto({ success: true, url });
      setOpenImage(true);
    } catch (error) {
      setError(true);
      console.log(error);
    }
    handleLoading(false);
  };

  React.useEffect(() => {
    binFromBrowser && setLoading(false);
    !binFromBrowser && fetchFromURL();
  }, []);
  return (
    <>
      {!loading &&
        (!error ? (
          <>
            <Container className="d-flex">
              <Button onClick={() => navigate("/check-bins")}>
                <AiOutlineRollback />
              </Button>
              <div className="d-flex justify-content-center align-items-center w-100">
                Κάδος {{ glass: "Υαλικών", clothes: "Ρουχισμού", battery: "Μπαταριών" }[bin.bin_type]}
              </div>
            </Container>
            <Container className="d-flex flex-column mt-3">
              <div className="d-flex my-1">Latitude: {bin.coords.latitude}</div>
              <div className="d-flex my-1">Logitude: {bin.coords.longitude}</div>
              <div className="d-flex my-1">User: {bin.email}</div>
            </Container>

            <Buttons bin={bin} id={id} />
            <Container className="d-flex justify-content-evenly mt-4">
              <Button onClick={() => setOpenMap(true)}>
                <BsPinMapFill size={30} />
              </Button>
              <Button onClick={() => bin && fetchimage()}>
                <BsCameraFill size={30} />
              </Button>
            </Container>

            <ModalMap show={openMap} setShow={setOpenMap} coords={bin.coords} />
            <ModalImage show={openImage} setShow={setOpenImage} photo={photo} />
          </>
        ) : (
          <NotFound />
        ))}
    </>
  );
};

const mapStateToProps = (state) => ({
  binFromBrowser: state.demands?.[state.current.demand],
});

const mapDispatchToPros = (dispatch) => ({
  handleLoading: (value) => dispatch(SetLoading(value)),
});

export default connect(mapStateToProps, mapDispatchToPros)(BinCheck);
