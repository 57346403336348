import { get, ref } from "firebase/database";
import React from "react";
import { Form, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { auth, database } from "../firebase";
import { SetAuth, SetLoading } from "../redux/actions";
import { signInWithEmailAndPassword } from "firebase/auth";

const Auth = ({ setIsAuth, isAuth, setLoading }) => {
  const first = React.useRef();
  const second = React.useRef();
  const third = React.useRef();
  const fourth = React.useRef();

  const [pin, SetPin] = React.useState("");

  const [wrongPin, setWrongPin] = React.useState(false);

  const [firstValue, setFirstValue] = React.useState("");
  const [secondValue, setSecondValue] = React.useState("");
  const [thirdValue, setThirdValue] = React.useState("");
  const [fourthValue, setFourthValue] = React.useState("");

  const fetchPin = async () => {
    await get(ref(database, "settings/pin")).then((snap) => SetPin(snap.val()));
  };

  const handlePIN = (ff) => {
    if (
      firstValue == pin.toString().charAt(0) &&
      secondValue == pin.toString().charAt(1) &&
      thirdValue == pin.toString().charAt(2) &&
      ff == pin.toString().charAt(3)
    ) {
      setLoading(true);
      signInWithEmailAndPassword(auth, process.env.REACT_APP_ADMIN_EMAIL, process.env.REACT_APP_ADMIN_PASS)
        .then((res) => {
          console.log(res);
          setIsAuth(true);
        })
        .catch((res) => {
          console.log(res);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setWrongPin(true);
      setTimeout(() => {
        setWrongPin(false);
        setFirstValue("");
        setSecondValue("");
        setThirdValue("");
        setFourthValue("");
        first.current.focus();
      }, 500);
    }
  };
  const onFirst = (e) => {
    if (parseInt(e.key) >= 0 && parseInt(e.key) <= 9) {
      setFirstValue(e.key);
      second.current.focus();
      return;
    }
    if (e.key == "Backspace") setFirstValue("");
  };
  const onSecond = (e) => {
    if (parseInt(e.key) >= 0 && parseInt(e.key) <= 9) {
      setSecondValue(e.key);
      third.current.focus();
      return;
    }
    if (e.key == "Backspace") {
      if (secondValue == "") {
        setFirstValue("");
        first.current.focus();
      } else {
        setSecondValue("");
      }
    }
  };
  const onThird = (e) => {
    if (parseInt(e.key) >= 0 && parseInt(e.key) <= 9) {
      setThirdValue(e.key);
      fourth.current.focus();
      return;
    }
    if (e.key == "Backspace") {
      if (thirdValue == "") {
        setSecondValue("");
        second.current.focus();
      } else {
        setThirdValue("");
      }
    }
  };
  const onFourth = (e) => {
    if (parseInt(e.key) >= 0 && parseInt(e.key) <= 9) {
      setFourthValue(e.key);
      handlePIN(parseInt(e.key));
      return;
    }
    if (e.key == "Backspace") {
      if (fourthValue == "") {
        setThirdValue("");
        third.current.focus();
      } else {
        setFourthValue("");
      }
    }
  };

  React.useEffect(() => {
    fetchPin();
  }, []);

  return (
    <Modal
      contentClassName={`bg-dark text-light${wrongPin ? " pin-wrong" : ""}`}
      show={!isAuth}
      centered
      backdrop="static"
      keyboard={false}
    >
      {/* <Modal.Header className="d-flex justify-content-center align-items-center">Login</Modal.Header> */}
      <Modal.Body>
        <Form className="d-flex flex-row justify-content-evenly" style={{}}>
          <Form.Control
            onKeyDownCapture={onFirst}
            value={firstValue}
            ref={first}
            className="pin-input"
            type="password"
            inputMode="numeric"
            autoComplete="false"
          />
          <Form.Control
            onKeyDownCapture={onSecond}
            value={secondValue}
            ref={second}
            className="pin-input"
            type="password"
            inputMode="numeric"
            autoComplete="false"
          />
          <Form.Control
            onKeyDownCapture={onThird}
            value={thirdValue}
            ref={third}
            className="pin-input"
            type="password"
            inputMode="numeric"
            autoComplete="false"
          />
          <Form.Control
            onKeyDownCapture={onFourth}
            value={fourthValue}
            ref={fourth}
            className="pin-input"
            type="password"
            inputMode="numeric"
            autoComplete="false"
          />
        </Form>
      </Modal.Body>
      {/* <Modal.Footer className="d-flex justify-content-center align-items-center">
        <Button variant="warning">Σύνδεση</Button>
      </Modal.Footer> */}
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isAuth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setIsAuth: (value) => dispatch(SetAuth(value)),
  setLoading: (value) => dispatch(SetLoading(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
