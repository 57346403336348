import { ref, remove, update } from "firebase/database";
import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { database } from "../../../firebase";
import { MoveBin, SetOpenMoveBin } from "../../../redux/actions";
import Loader from "../Loader";
const ModalConfirmMoveBin = ({ show, setShow, bin, fetchBins }) => {
  const [loading, setLoading] = React.useState(false);
  const handleAccept = async () => {
    setLoading(true);
    try {
      await update(ref(database, `bins/${bin.type}/${bin.id}`), {
        coords: bin.newCoords,
      });
      //   await remove(ref(database, `images/${id}`));
      //   await remove(ref(database, `pending-demands/${id}`));
      //   removeFromList(id);
      //   navigate("/check-bins");
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setShow(false);
  };

  const handleCancel = async () => {
    fetchBins();
    setShow(false);
  };

  return (
    <Modal centered show={show} onHide={() => setShow(false)}>
      {loading && <Loader />}
      <Modal.Header className="d-flex justify-content-center align-items-center">Μετακίνηση Κάδου</Modal.Header>
      <Modal.Body className="d-flex justify-content-center align-items-center">Ενημέρωση της εφαρμογής</Modal.Body>
      <Modal.Footer className="d-flex justify-content-evenly align-items-center">
        <Button onClick={handleCancel} variant="danger">
          Ακύρωση
        </Button>
        <Button onClick={handleAccept} variant="success">
          Επιβεβαίωση
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  show: state.ui.moveBin.show,
  bin: state.ui.moveBin.bin,
});

const mapDispatchToPros = (dispatch) => ({
  setShow: (value) => dispatch(SetOpenMoveBin(value)),
  moveBin: (type, id, coords) => dispatch(MoveBin(type, id, coords)),
});

export default connect(mapStateToProps, mapDispatchToPros)(ModalConfirmMoveBin);
