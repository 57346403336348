import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BinCheck from "../components/BinCheck";
import ListBins from "../components/bins/ListBins";
import Home from "../components/Home";
import List from "../components/List";
import NavBar from "../components/ui/NavBar";
import NotFound from "./NotFound";

const Routing = () => {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route exact path="/check-bins" element={<List />}></Route>
        <Route exact path="/bins" element={<ListBins />}></Route>
        <Route exact path="/check-bins/:id" element={<BinCheck />}></Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default Routing;
